import { coreServices } from '@/services/coreServices';
import cookieManager from '@/store/cookieManager';

export const fileServices = {
  /*
    Inbox
    --------------------------
  */
  getInboxData(onlyCount) {
    return coreServices.get(`/files/inbox/?count=${onlyCount ? 1 : 0}`, coreServices.getRequestHeader(), '');
  },
  /*
    UP DL
    --------------------------
  */
  uploadFile(archive) {
    const headers = coreServices.getRequestHeader({
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': cookieManager.get('csrftoken'),
    });
    const responses = [];
    Array.from(archive.files).forEach((element) => {
      const data = new FormData();
      data.append('title', element.name);
      data.append('document_type', element.type);
      data.append('file_location', element); // REVISAR
      data.append('name', element.name);
      data.append('data_created', new Date().toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(',', ''));
      data.append('attached_files', archive.files.length);
      responses.push(coreServices.post('/files/', data, headers));
    });
    return responses;
  },
  downloadTreeFiles(studyId) {
    return coreServices.downloadGetBlob(
      `/files/studies/${studyId}/download-tree-files/`,
      coreServices.getRequestHeader({ 'X-CSRFToken': cookieManager.get('csrftoken') }),
    );
  },
  /*
    Study
    --------------------------
  */
  getStudies() {
    return coreServices.get('/files/studies/', coreServices.getRequestHeader(), '');
  },
  getStudyList() {
    return coreServices.get('/files/studies/study-list/', coreServices.getRequestHeader(), '');
  },
  createStudy(data) {
    return coreServices.post('/files/studies/', data, coreServices.getRequestHeader());
  },
  getStudy(studyId) {
    return coreServices.get(`/files/studies/${studyId}/`, coreServices.getRequestHeader(), {});
  },
  updateStudy(studyId, data) {
    return coreServices.put(`/files/studies/${studyId}/`, data, coreServices.getRequestHeader());
  },
  deleteStudy(studyId, deleteStudyComment) {
    return coreServices.post(
      `/files/studies/${studyId}/delete/`, { comment: deleteStudyComment }, coreServices.getRequestHeader(),
    );
  },
  /*
    Study site
    --------------------------
  */
  getStudySites(studyCountryId = null, studyId = null) {
    const params = {};
    if (studyCountryId) {
      params.study_country_id = studyCountryId;
    }
    if (studyId) {
      params.study_id = studyId;
    }
    return coreServices.get('/files/study-sites/', coreServices.getRequestHeader(), params);
  },
  setNewStudySite(data) {
    return coreServices.post('/files/study-sites/', data, coreServices.getRequestHeader());
  },
  deleteStudySite(studySiteId) {
    return coreServices.delete(`/files/study-sites/${studySiteId}/`, coreServices.getRequestHeader());
  },
  updateStudySite(studySiteId, data) {
    return coreServices.put(`/files/study-sites/${studySiteId}/`, data, coreServices.getRequestHeader());
  },
  /*
    Study country
    --------------------------
  */
  setNewStudyCountry(data) {
    return coreServices.post('/files/study-countries/', data, coreServices.getRequestHeader());
  },
  getStudyCountryList() {
    return coreServices.get('/files/study-countries/', coreServices.getRequestHeader(), '');
  },
  getStudyCountryListFiltered(studyId) {
    return coreServices.get(
      `/files/study-countries/filtered/?study_id=${studyId}`, coreServices.getRequestHeader(), '',
    );
  },
  deleteStudyCountry(studyCountryId) {
    return coreServices.delete(`/files/study-countries/${studyCountryId}/`, coreServices.getRequestHeader());
  },
  updateStudyCountry(id, name) {
    return coreServices.put(`/files/study-countries/${id}/`, { name: name }, coreServices.getRequestHeader());
  },
  /*
    Study users
    --------------------------
  */
  getStudyUserList(studyId) {
    return coreServices.get(`/files/study-users/?study_id=${studyId}`, coreServices.getRequestHeader(), {});
  },
  updateStudyUser(studyId, data) {
    return coreServices.post(`/files/study-users/?study_id=${studyId}`, data, coreServices.getRequestHeader());
  },
  /*
    Files
    --------------------------
  */
  /** Get a list of all users that are owners of at least 1 document */
  getKpiOwners() {
    return coreServices.get('/files/owners/', coreServices.getRequestHeader(), '');
  },
  /**
   * Get the history of all Data or a specific one
   * @param {int} fileId Id for the Data to filter
   */
  getHistoric(fileId) {
    const params = { id: fileId };
    return coreServices.get('/files/history/', coreServices.getRequestHeader(), params);
  },
  classifyFile(data) {
    const header = coreServices.getRequestHeader();
    const responses = [];
    data.forEach((element) => {
      responses.push(coreServices.post(`/files/${element.file_id}/classify/`, element, header));
    });
    return responses;
  },
  getFilesPendingClassify() {
    return coreServices.get('/files/pending-classify/', coreServices.getRequestHeader());
  },
  /** Get file library */
  getLibraryData(params) {
    return coreServices.get('/files/', coreServices.getRequestHeader(), params);
  },
  getFileDates() {
    return coreServices.get('/files/dates/', coreServices.getRequestHeader(), '');
  },
  /**
   * Get the list of users that is owner of at least 1 Data object
   * @return axios object
   */
  getFile(fileId) {
    return coreServices.get(`/files/${fileId}/`, coreServices.getRequestHeader(), {});
  },
  updateFile(fileId, data) {
    const headers = coreServices.getRequestHeader({ 'Content-Type': 'multipart/form-data' });
    return coreServices.post(`/files/${fileId}/update-file/`, data, headers);
  },
  deleteFile(fileId, comment) {
    return coreServices.delete(`/files/${fileId}/`, coreServices.getRequestHeader(), { comment });
  },
  // getValidateData(fileId) {
  //   const url = `/files/${fileId}/validated-data/`;
  //   const params = { id: fileId };
  //   return coreServices.get(url, coreServices.getRequestHeader(), params);
  // },
  approveUpdate(fileId) {
    return coreServices.post(`/files/${fileId}/approve/`, {}, coreServices.getRequestHeader());
  },
  noApproveUpdate(fileId, data) {
    return coreServices.post(`/files/${fileId}/reject/`, data, coreServices.getRequestHeader());
  },
  dataUpdateClassification(fileId, data) {
    return coreServices.post(`/files/${fileId}/data-update-classification/`, data, coreServices.getRequestHeader());
  },
  passApproveToOther(fileId, data) {
    return coreServices.post(`/files/${fileId}/pass-approve-to-other/`, data, coreServices.getRequestHeader());
  },
  passApproveToOtherList(data) {
    const responses = [];
    data.forEach((element) => {
      responses.push(
        this.passApproveToOther(
          element.file_id,
          { user_rms1_id: element.new_approver_user_id, commentary: element.commentary },
        ),
      );
    });
    return responses;
  },
  getMetadataFiles(folderId) {
    const data = { id: folderId };
    return coreServices.get('/files/metadata-files/', coreServices.getRequestHeader(), data);
  },
  getFileListStatus(studyId, filters = null) {
    let params = { study_id: studyId };
    if (filters !== null) params = { ...params, ...filters };
    return coreServices.get('/files/file-list-status/', coreServices.getRequestHeader(), params);
  },
  /*
    Feedback
    --------------------------
  */
  sendEmailFeedback(archive, feedbackComment) {
    const headers = coreServices.getRequestHeader({
      'Content-Type': 'multipart/form-data',
      'X-CSRFToken': cookieManager.get('csrftoken'),
    });
    const data = new FormData();
    data.append('feedback', feedbackComment);
    if (archive) {
      data.append('title', archive.name);
      data.append('file_data', archive);
      data.append('file_type', archive.type);
    }
    return coreServices.post('/files/send-email-feedback/', data, headers);
  },
};
