<template>
    <div id="app">
        <div id="navbar-sidebar" class="sidebar">
          <v-navigation-drawer permanent expand-on-hover>
            <v-list v-for="route in filteredTabs" :key="route.tabName" nav dense>
              <v-list-item link :to="route.path">
                <v-list-item-icon>
                  <v-icon>{{ route.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{route.tabName}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </div>
    </div>
</template>
<script>
import { roleManager } from '@/store/roleManager';
import cookieManager from '@/store/cookieManager';

export default {
  name: 'Header',
  data() {
    return {
      tabs: [
        { tabName: 'HOME', path: '/home', icon: 'mdi-home' },
        { tabName: 'LIBRARY', path: '/library', icon: 'mdi-book-open-variant' },
        { tabName: 'DASHBOARDS', path: '/dashboards', icon: 'mdi-monitor-dashboard' },
        { tabName: 'STUDIES', path: '/studies', icon: 'mdi-notebook-multiple' },
        { tabName: 'MILESTONES', path: '/milestones', icon: 'mdi-flag-triangle' },
        { tabName: 'AUDIT TRAIL', path: '/audit-trail', icon: 'mdi-folder-clock' },
        { tabName: 'ADMIN', path: '/control-panel', icon: 'mdi-account-supervisor' },
        { tabName: 'AUDIT USERS', path: '/audit-users', icon: 'mdi-account-clock' },
      ],
    };
  },
  computed: {
    filteredTabs() {
      if (!cookieManager.hasReadAllAppDocuments()) {
        return [];
      }
      if (!roleManager.isAnyAdmin()) {
        return this.tabs.filter((tab) => !['ADMIN', 'AUDIT USERS'].includes(tab.tabName));
      }
      return this.tabs;
    },
  },
  mounted() {
    // this.controlPanelTab();
  },
  // methods: {
  //   controlPanelTab() {
  //     const tab = { tabName: 'ADMIN', path: '/control-panel', icon: 'mdi-account-supervisor' };
  //     const tabAuditUsers = { tabName: 'AUDIT USERS', path: '/audit-users', icon: 'mdi-account-clock' };
  //     if (roleManager.isAnyAdmin()) {
  //       this.tabs.push(tab);
  //       this.tabs.push(tabAuditUsers);
  //     }
  //     if (!cookieManager.hasReadAllAppDocuments()) {
  //       thi
  //     }
  //   },
  // },
};
</script>
